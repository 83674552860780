<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.measurements"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('message.name')"
                                    class="label_mini"
                                    prop="name"
                                >
                                    <el-input
                                        :placeholder="$t('message.name')"
                                        v-model="form.name"
                                        size="medium"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('message.full_name')"
                                    class="label_mini"
                                >
                                    <el-input
                                        :placeholder="$t('message.full_name')"
                                        v-model="form.full_name"
                                        size="medium"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('message.code')"
                                    class="label_mini"
                                >
                                    <el-input
                                        :placeholder="$t('message.code')"
                                        v-model="form.code"
                                        size="medium"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('message.status')"
                                    class="label_mini"
                                >
                                    <select-status
                                        :size="'medium'"
                                        :placeholder="columns.status.title"
                                        :id="form.status"
                                        v-model="form.status"
                                    >
                                    </select-status>
                                </el-form-item>
                            </el-col>

                            <!-- end-col -->

                            <!-- end-col -->
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectStatus from "@/components/inventory/select-status";
export default {
    components: {
        selectStatus,
    },
    mixins: [form, drawer, show],
    name: "measurements",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "measurements/rules",
            model: "measurements/model",
            columns: "measurements/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "measurements/update",
            show: "measurements/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
